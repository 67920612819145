<script setup lang="ts">
import type { ITrackEntity, TVideoEntity } from '@@/types'

import dkrLogoDark from '@@/assets/img/dkr-logo-dark-300.png'

const dkrLogoDarkUrl = computed(() => `https://dkrecs.com${dkrLogoDark}`)
const metaData = computed(() => {
  return {
    title: 'Denis Kenzo Recordings | senatee - Official Website',
    description: 'Releases, Singles, Albums, Videos, Contacts'
  }
})

useHead({
  title: metaData.value.title,
  meta: [
    { name: 'description', content: metaData.value.description},
    // Open Graph
    { property: 'og:title', content: metaData.value.title },
    { property: 'og:description', content: metaData.value.description },
    { property: 'og:url', content: 'https://dkrecs.com' },
    { property: 'og:image', content: dkrLogoDarkUrl.value },
    { property: 'og:image:alt', content: metaData.value.title },
    { property: 'og:image:width', content: "300" },
    { property: 'og:image:height', content: "300" },
    { property: 'og:type', content: 'website' },
    { property: 'fb:app_id', content: '1520706015228291' },
  ]
})

const config = useRuntimeConfig()
const { baseURL } = config.public
const { data: trackList } = await useFetch<ITrackEntity[] | null>(`${baseURL}/tracks.php`)

const latestRelease = computed(() => trackList.value?.[0])
const albumList = computed(() => trackList.value?.filter(({ type }) => type === 'album'))
const singleList = computed(() => 
  trackList.value
    ?.filter(({ type }) => type === 'single')
    ?.slice(1)
    .slice(0, 2)
)
const { data: videoList } = await useFetch<TVideoEntity[] | null>(`${baseURL}/videos.php`)
const videoListShort = computed(() => videoList.value?.slice(0, 2))
</script>

<template>
  <div v-if="trackList">

    <!-- Latest -->
    <div
      v-if="latestRelease"
      class="mb-4">
      <!-- <h2 class="text-size-3.5 font-400">Latest</h2> -->
      <TrackLatestBig :trackItem="latestRelease" />
    </div>

    <!-- Singles -->
    <div v-if="singleList">
      <TrackOther
        v-for="(item, index) in singleList"
        :key="index"
        :track="item"
        class="mb-4" />
      <NuxtLink
        to="/tracks"
        title="Go to tracks page"
        class="more-link">more tracks...</NuxtLink>
    </div>

    <!-- Albums -->
    <div v-if="albumList">
      <h2 class="mt-6 mb-4 text-size-3.5 font-400">Albums</h2>
      <TrackOther
        v-for="(item, index) in albumList"
        :key="index"
        :track="item"
        :class="{'mb-4': index != (albumList.length-1)}" />
    </div>

    <!-- Videos -->
    <div v-if="videoListShort">
      <h2 class="mt-6 mb-4 text-size-3.5 font-400">Official videos</h2>
      <NuxtLink
        v-for="video in videoListShort"
        :key="video.youtubeVideoId"
        :to="{ 
          path: `/videos/${video.videoUri}`,
          state: { videoId: video.youtubeVideoId }
        }"
        :title="`${video.artist} - ${video.track}`">
        <VideoCard :videoData="video"/>
      </NuxtLink>

      <div class="mt-4">
        <NuxtLink
          to="/videos"
          title="Go to tracks page"
          class="more-link">more videos...</NuxtLink>
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">
.more-link {
  color: var(--color-accent-lighter);
}
</style>
